<template>
    <div class="timeseries" style="height:100%;padding-top:88px;padding-bottom:64px;">
        <div class="head clear shaow" style="margin-top:-88px;position: relative;">
            <div class="head_top clear">
                <div class="head_item cleft">
                    <div class="headlogo">
                        <router-link to="/Home"><img src="/img/epslogo.png" alt=""></router-link>
                    </div>
                </div>
                <div class="headsearch cleft">
                    <div class="searchbox">
                        <input type="text" v-model="urlName" @keyup="getInput($event)">
                        <div class="seabtn" @click="getSearchBtn()">
                            <i class="gwicon searchs"></i>
                        </div>
                    </div>
                </div>
                <div class="head_group cright">
                    <ul class="headlink cright">
                        <li><a href="https://a8-im.7x24cc.com/phone_webChat.html?accountId=N000000050896&chatId=11563011-8e54-438d-a271-36964839cd53" target="_blank">在线咨询</a></li>
                        <li><a href="http://www.epschinadata.com/" target="_blank">English</a></li>
                    </ul>
                    <!-- <div class="headName cright" v-show="userObj.groupName">欢迎您，<span>{{userObj.groupName}}</span></div> -->
                </div>
            </div>
        </div>
        <div style="min-height:100%;width:100%;backgroup:#fdfdfd;">
            <div class="containers mrg20" v-show="showLoading==false">
                <div class="screenGroup cleft">
                    <div class="screenSelect">
                        <h4 class="titleSelect" @click="orgFlag=!orgFlag">区域</h4>
                        <ul class="listSelect" :class="orgFlag==false?'highid':''" v-if="orgList.length>5">
                            <li class="clear" :class="orgCurrent==v.REGCLC_ID?'current':''" v-for="(v,k) in orgList" :key="k" @click="getOrg(v)">
                                <div class="titname cleft">
                                    <span class="titles">{{v.name}}</span>
                                    <span class="numbers">({{v.count}})</span>
                                </div>
                                <div class="clicks cright" v-show="orgCurrent==v.REGCLC_ID" @click.stop="getOrgRemove">&times;</div>
                            </li>
                        </ul>
                        <ul class="listSelect" v-else>
                            <li class="clear" :class="orgCurrent==v.REGCLC_ID?'current':''" v-for="(v,k) in orgList" :key="k" @click="getOrg(v)">
                                <div class="titname cleft">
                                    <span class="titles">{{v.name}}</span>
                                    <span class="numbers">({{v.count}})</span>
                                </div>
                                <div class="clicks cright" v-show="orgCurrent==v.REGCLC_ID" @click.stop="getOrgRemove">&times;</div>
                            </li>
                        </ul>
                    </div>
                    <div class="screenSelect">
                        <h4 class="titleSelect" @click="dataTimeFlag=!dataTimeFlag">最新时间<i class="gwicon screens" v-if="datatimelist.length>5"></i></h4>
                        <ul class="listSelect" :class="dataTimeFlag==false?'highid':''" v-if="datatimelist.length>5">
                            <li class="clear" :class="dataTimeCurrent==v.YEARS?'current':''" v-for="(v,k) in datatimelist" :key="k" @click="getDataTime(v)">
                                <div class="titname cleft">
                                    <span class="titles">{{v.YEARS}}</span>
                                    <span class="numbers">({{v.count}})</span>
                                </div>
                                <div class="clicks cright" v-show="dataTimeCurrent==v.YEARS" @click.stop="getDataTimeRemove">&times;</div>
                            </li>
                        </ul>
                        <ul class="listSelect" v-else>
                            <li class="clear" :class="dataTimeCurrent==v.YEARS?'current':''" v-for="(v,k) in datatimelist" :key="k" @click="getDataTime(v)">
                                <div class="titname cleft">
                                    <span class="titles">{{v.YEARS}}</span>
                                    <span class="numbers">({{v.count}})</span>
                                </div>
                                <div class="clicks cright" v-show="dataTimeCurrent==v.YEARS" @click.stop="getDataTimeRemove">&times;</div>
                            </li>
                        </ul>
                    </div>
                    <div class="screenSelect">
                        <h4 class="titleSelect" @click="areasFlag=!areasFlag">数据来源<i class="gwicon screens" v-if="areasList.length>5"></i></h4>
                        <ul class="listSelect" :class="areasFlag==false?'highid':''" v-if="areasList.length>5">
                            <li class="clear" :class="areasCurrent==v.SOURCE_ZH?'current':''" v-for="(v,k) in areasList" :key="k" @click="getArea(v)">
                                <div class="titname cleft">
                                    <span class="titles">{{v.SOURCE_ZH}}</span>
                                    <span class="numbers">({{v.count}})</span>
                                </div>
                                <div class="clicks cright" v-show="areasCurrent==v.SOURCE_ZH" @click.stop="getAreaRemove">&times;</div>
                            </li>
                        </ul>
                        <ul class="listSelect" v-else>
                            <li class="clear" :class="areasCurrent==v.SOURCE_ZH?'current':''" v-for="(v,k) in areasList" :key="k" @click="getArea(v)">
                                <div class="titname cleft">
                                    <span class="titles">{{v.SOURCE_ZH}}</span>
                                    <span class="numbers">({{v.count}})</span>
                                </div>
                                <div class="clicks cright" v-show="areasCurrent==v.SOURCE_ZH" @click.stop="getAreaRemove">&times;</div>
                            </li>
                        </ul>
                    </div>                    
                </div>
                <div class="contenFroup cright">
                    <div class="screenTtile">
                        <div class="cleft">
                            <span class="tip_titles">共检索到{{thousandSplit(totalSize)}}条记录检索结果</span>
                        </div>
                        <div class="selectsGroup cright" style="display:none;" @click.stop="getScreen()">
                            <h4 class="selectsName">{{screenObj.name}}<i class="gwicon iconSelect"></i></h4>
                            <ul class="selectsItem" v-show="listFlag">
                                <li v-for="(v,k) in screenLists" :key="k" @click="getItem(v)">{{v.name}}</li>
                            </ul>
                        </div>
                    </div>
                    <i-col class="demo-spin-col" v-if="loader">
                        <Spin fix>加载中...</Spin>
                    </i-col>
                    <div class="screenTable" v-else>
                        <ul class="fromsGroup" v-if="list.length>0">
                            <!--  @click="getDetail" -->
                            <li v-for="(v,k) in list" :key="k">
                                <!-- <a class="title" href="javascript:void(0" @click="getDetail(v.DFFILE_ID)" v-html="v.TITLE"></a> -->
                                <a class="title" target="_blank" :href="'http://dfms.epsnet.com.cn/file.jsp#/'+v.DFFILE_ID+'/'+urlName" v-html="v.TITLE" :title="getInnerText(v.TITLE)"></a>
                                <div class="title_name">
                                    <span>地区：
                                        <span v-show="v.REGCLC==0">全球</span>
                                        <span v-show="v.REGCLC==1">全国</span>
                                        <span v-show="v.REGCLC==2">省级</span>
                                        <span v-show="v.REGCLC==3">市级</span>
                                        <span v-show="v.REGCLC==4">县级</span>
                                    </span>
                                    <span>来源：{{v.SOURCE_ZH}}</span> 
                                    <span>频度：
                                        <span v-show="v.FREQ_ID==1">年度</span>
                                        <span v-show="v.FREQ_ID==3">季度</span>
                                        <span v-show="v.FREQ_ID==4">月度</span>
                                    </span>
                                    <span>数据量：{{v.DATA_VOLUME}}</span>
                                </div>
                                <div class="title_time">
                                    <span v-show="v.ELEMENTS" :title="v.ELEMENTS">要素：{{v.ELEMENTS}}</span>
                                </div>
                            </li>
                        </ul>
                        <i-col class="demo-spin-col" v-else>
                            <Spin fix>未查询到搜索结果数据</Spin>
                        </i-col>
                    </div>
                    <div class="screenPage" style="text-align:center">
                        <Page :current="currentPage" v-show="totalSize>pageSize&&loader!=true" :page-size="pageSize" :total="totalSize" @on-change="jumpPage" />
                    </div>
                </div>
            </div>
        </div>
        <Footer></Footer>
        <div class="loading" v-show="showLoading==true">            
            <div class="loadingInner"></div>
        </div>
        <!-- <Modals :text="str" v-show="modalsFlag"></Modals> -->
    </div>
</template>
<script>
import Footer from './commons/Footer.vue';
// import Modals from './commons/Modals.vue';
import { 
    // urlEpsBak,
    ajaxPost,
    // getSessionItem,
} from "../until/common";

export default {
    name:'Reportform',
	components:{
        Footer,
        // Modals
    },
    data() {
        return {
            showLoading:false,
            loader:false,
            // userObj:{},
            urlName:'',
            scrollTop:0,
            // sid:'',
            searchName:'',
            // modalsFlag:false,
            // str:'',
            currentPage:1,
            totalPage:0,
            totalSize:0,
            pageSize:0,
            list:[],
            screenObj:{},
            listFlag:false,
            screenLists:[
                {sort:'0',name:'相关度优先'},
                {sort:'1',name:'发布时间倒序'},
            ],

            areasCurrent:0,
            areasFlag:false,
            areasList:[],

            dataTimeCurrent:0,
            dataTimeFlag:false,
            datatimelist:[],
            
            orgCurrent:'',
            orgFlag:false,
            orgList:[],
            
            dataTimeObj:{},
            orgObj:{},
            areasObj:{},
        }
    },
    computed: {
        conditions() {
            return {
                sort:this.screenObj.sort,//相关度
                keyword: this.searchName,//搜索关键词
                page: this.currentPage,//当前页数
                year:this.dataTimeObj.YEARS?this.dataTimeObj.YEARS:'',//发布时间
                cubeId: this.orgObj.REGCLC_ID?this.orgObj.REGCLC_ID:'',//区域筛选
                org: this.areasObj.SOURCE_ZH?this.areasObj.SOURCE_ZH:'',//出版物SOURCE_ZH
               
            }
        }
    },
    watch:{
        conditions() {
            this.getInit();
        },
    },
    methods:{
        getInnerText(str) {
            return str.replace(/<(style|script|iframe)[^>]*?>[\s\S]+?<\/\1\s*>/gi,'').replace(/<[^>]+?>/g,'').replace(/\s+/g,' ').replace(/ /g,' ').replace(/>/g,' ')
        },
        getInput(event){
            var that = this;
            const code = event.keyCode ? event.keyCode : event.which ? event.which : event.charCode;
            if (code == 13) {
                that.searchName = that.urlName;
                that.getInit();
            }
        },
        getSearchBtn(){
            var that = this;
            that.searchName = that.urlName;
            that.getInit();
        },
        thousandSplit(num) {
            return ("" + num).replace(/(\d{1,3})(?=(\d{3})+(?:$|\.))/g, "$1,")
        },

        getScreen:function(){
            this.listFlag = !this.listFlag;
        },
        getItem:function(v){
            this.screenObj = v;
        },
        //
        getArea(item){
            this.areasObj = item;
            this.areasCurrent = item.SOURCE_ZH;
        },
        getAreaRemove(){
            this.areasCurrent=0;
            this.areasObj = {};
        },
        getDataTime(item){
            this.dataTimeObj = item;
            this.dataTimeCurrent = item.YEARS;
        },
        getDataTimeRemove(){
            this.dataTimeCurrent=0;
            this.dataTimeObj = {};
        },
        getOrg(item){
            this.orgObj = item;
            this.orgCurrent = item.REGCLC_ID;
        },
        getOrgRemove(){
            this.orgCurrent='';
            this.orgObj = {};
        },
        /* getDetail(id){
            if(this.sid){
                window.open('http://dfms.epsnet.com.cn/file.jsp#/'+id);
            }else{
                this.$store.commit('setMadels',`${urlEpsBak+'login.html'}`);
            }
        }, */
        
        getInit(){
            var that = this;
            var params = {};
            for(var i in this.conditions) {
                if(this.conditions[i]) {
                    params[i] = this.conditions[i];
                }
            }
            that.loader = true;
            that.timesLists = [];
            that.showLoading = true;
             /* 
            getSearchExcel
            
            */
            ajaxPost('getSearchExcel',params).then(function(res) {
                if (res.data) {
                    that.areasList = res.data.factMap?(res.data.factMap.orgList?res.data.factMap.orgList:[]):[];//区域
                    that.datatimelist = res.data.factMap?(res.data.factMap.dateList?res.data.factMap.dateList:[]):[];//发布时间
                    that.orgList = res.data.factMap?(res.data.factMap.cubeList?res.data.factMap.cubeList:[]):[];//出版物
                    that.list = res.data?res.data.result:[];//列表数据
                    that.currentPage = res.data.page?res.data.page.currentPage:0;
                    that.totalPage = res.data.page?res.data.page.totalPageSize:0;
                    that.totalSize = res.data.page?res.data.page.totalResultSize:0;
                    that.pageSize = res.data.page?res.data.page.pageSize:0;
                    that.loader = false;
                    that.showLoading = false;
                }else{
                    that.areasList = [];
                    that.datatimelist = [];
                    that.orgList = [];
                    that.list = [];
                }
            }).catch(function(error) {
                window.console.log(error);
            });
            
        },
        jumpPage(current){
            this.currentPage = current;
            this.backTop();
            // document.body.scrollTop = 0;
            // document.documentElement.scrollTop = 0;
            // this.getInit();
        },
        backTop () {
            // 回到顶部
            let that = this
            let timer = setInterval(() => {
                let ispeed = Math.floor(-that.scrollTop / 5);
                document.documentElement.scrollTop = document.body.scrollTop = that.scrollTop + ispeed;
                if (that.scrollTop === 0) {
                    clearInterval(timer)
                }
            }, 16);
        },
        handleScroll() {
            let that = this
            let scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop
            that.scrollTop = scrollTop;
        },
    },
    mounted() {
        this.screenObj = this.screenLists[0];
        var that = this;
        if(that.$route.params.sName){
            that.searchName = that.$route.params.sName;
            that.urlName = that.$route.params.sName;
        }
        that.getInit();
        /* if(getSessionItem('userData')){
            that.userObj = getSessionItem('userData');
            that.sid = getSessionItem('userData').sid;
        } */
        document.onclick = () => {
            that.listFlag = false;
        }
        window.addEventListener('scroll', this.handleScroll)
    },
    destroyed() {
        window.removeEventListener('scroll', this.handleScroll)
    }
}
</script>
<style lang="scss">

</style>